<template>
  <loading-dialog :loading="$apollo.loading">
    <base-page-layout title="Criar conta">
      <v-card>
        <v-card-text>
          <v-row justify="end">
            <v-col cols="auto">
              <v-icon @click="startTour">info</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col id="first-section" cols="6">
              <label class="card-title">Nome*</label>
              <v-text-field v-model="name" outlined :error-messages="(getSafe(() => $v.name.$error) && 'Insira um nome válido.') || ''" />
            </v-col>
            <v-col id="second-section" cols="6">
              <label class="card-title">Acrônimo</label>
              <v-text-field
                v-model="acronym"
                :rules="[v => /^[a-z_0-9]*$/.test(v) || 'O acrônimo deve conter apenas letras, underline e números válida.']"
                :error-messages="(getSafe(() => $v.acronym.$error) && 'Insira um acrônimo válido.') || ''"
                outlined
                disabled
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col id="third-section" cols="6">
              <label class="card-title">Inicio Fidelidade*</label>
              <date-picker
                :date.sync="loyaltyProgramStartDate"
                solo-inverted
                outline
                :error-messages="(getSafe(() => $v.loyaltyProgramStartDate.$error) && 'Insira uma data de início de clube.') || ''"
              />
            </v-col>
            <v-col id="fourth-section" cols="6">
              <label class="card-title">DB Name</label>
              <v-text-field
                disabled
                :value="dbName"
                outlined
                :error-messages="(getSafe(() => $v.DbName.$error) && 'DB Name deve ter no máximo 60 caracteres.') || ''"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col id="fifth-section" cols="6">
              <label class="card-title">ERP*</label>
              <v-autocomplete
                v-model="selectedERP"
                :items="erps"
                item-text="name"
                return-object
                outlined
                :error-messages="(getSafe(() => $v.selectedERP.acronym.$error) && 'Escolha um ERP.') || ''"
              />
            </v-col>
            <v-col id="sixth-section" cols="3" class="pt-8 pl-4">
              <v-radio-group v-model="integrationMode" mandatory row>
                <v-radio value="FULL_BACKOFFICE" label="Retaguarda" />
                <v-radio value="MIXED" label="Híbrido" />
                <v-radio value="FULL_POS" label="PDV" />
              </v-radio-group>
            </v-col>
            <v-col id="seventh-section" cols="3" class="pt-8">
              <v-radio-group v-model="accountStatus" mandatory row>
                <v-radio color="red" value="deploying" label="Implantando" />
                <v-radio color="red" value="test" label="Teste" />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col id="eighth-section">
              <v-row>
                <v-col cols="8">
                  <label class="card-title">Franquia</label>
                  <v-autocomplete
                    v-model="selectedFranchise"
                    :disabled="!isFranchisee"
                    :items="adminAccounts"
                    item-text="name"
                    item-value="_id"
                    outlined
                    :error-messages="(getSafe(() => $v.selectedFranchise.$error) && 'Escolha uma franquia.') || ''"
                  />
                </v-col>
                <v-col cols="3">
                  <v-row>
                    <v-col cols="auto">
                      <v-checkbox v-model="isFranchisee" label="Franqueado" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col id="ninth-section" cols="3" class="mt-4">
              <v-row no-gutetrs>
                <v-col cols="auto">
                  <v-checkbox v-model="isEcommerce" label="E-commerce" />
                </v-col>
                <v-col cols="auto">
                  <v-checkbox v-model="isCbm" label="CBM" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col id="tenth-section" cols="6">
              <label class="card-title">Varejista</label>
              <base-auto-complete
                v-model="selectedRetailer"
                :items="retailersData"
                item-text="formated_name"
                item-value="_id"
                :errors-messages="(getSafe(() => $v.selectedRetailer.$error) && 'Escolha um varejista.') || ''"
              />
            </v-col>
            <v-col id="eleventh-section">
              <v-row no-gutters>
                <v-col cols="12">
                  <label class="card-title">Indústria</label>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="3">
                  <v-checkbox v-model="hasMercapromo" label="Mercapromo" />
                </v-col>
                <v-col cols="3">
                  <mf-toggle
                    v-model="mercapromoWithCbm"
                    class="ml-4 mr-6"
                    :color="color"
                    :disabled="!hasMercapromo"
                    :label="mercapromoWithCbm ? 'Com CBM' : 'Sem CBM'"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col id="twelfth-section" cols="6" class="pt-8 pl-4">
              <v-row no-gutters>
                <v-col cols="12">
                  <label class="card-title">Conexão</label>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-radio-group v-model="connection" mandatory row>
                  <v-radio value="HOMOLOGACAO" label="Homologação" />
                  <v-radio value="SMB" label="SMB" />
                  <v-radio value="KA" label="KA" />
                  <v-radio value="RAF" label="RAF" />
                </v-radio-group>
              </v-row>
            </v-col>
          </v-row>

          <v-card-actions class="pl-0">
            <mf-action-buttons
              class="mt-2"
              :primary-button="{
                text: 'Criar Conta',
                action: create,
                isVisible: true,
                isDisabled: false
              }"
              :cancel-button="{
                text: 'Cancelar',
                action: () => $router.push('/accounts'),
                isVisible: true,
                isDisabled: false
              }"
            />
          </v-card-actions>
        </v-card-text>
      </v-card>
    </base-page-layout>
  </loading-dialog>
</template>

<script>
import _ from 'lodash'
import { maxLength, required } from 'vuelidate/lib/validators'
import { QUERY_GET_RETAILERS_PAGINATED } from '@/modules/retailers/graphql'
import { QUERY_GET_ACCOUNTS, QUERY_GET_ERPS, MUTATION_CREATE_ACCOUNT } from '@/modules/accounts/graphql'
import BaseAutoComplete from '../../components/atomic-components/atoms/BaseAutoComplete.vue'
import cnpj from '../../filters/cnpj'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue'),
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    DatePicker: () => import('@/components/calendar/DatePicker.vue'),
    BaseAutoComplete
  },
  data: () => ({
    name: '',
    loyaltyProgramStartDate: '',
    selectedERP: {},
    integrationMode: 'FULL_POS',
    isFranchisee: false,
    selectedFranchise: '',
    acronymTouched: false,
    localAcronym: '',
    fab: false,
    loading: false,
    accountStatus: '',
    disableCreate: false,
    isEcommerce: false,
    isCbm: false,
    hasMercapromo: false,
    mercapromoWithCbm: true,
    selectedRetailer: '',
    retailersData: [],
    color: '#334A58',
    connection: 'HOMOLOGACAO'
  }),
  computed: {
    erps() {
      return _.sortBy(this.adminERPs, ['name'])
    },
    acronym: {
      set(value) {
        this.acronymTouched = true
        this.localAcronym = value
      },
      get() {
        if (this.acronymTouched) return this.localAcronym
        const parsedAcronym = _.deburr(
          this.name
            .trimEnd()
            .split(' ')
            .join('_')
            .toLowerCase()
        ).replace(/[^0-9a-z_]/g, '')

        return parsedAcronym.replace(/_+/g, '_')
      }
    },
    dbName() {
      if (!Object.keys(this.selectedERP).length) return ''
      return `${String(this.selectedERP.erp_index).padStart(4, '0')}_${this.selectedERP.acronym}_${String(this.selectedERP.clients_index + 1).padStart(
        4,
        '0'
      )}_${this.localAcronym || this.acronym}`
    }
  },
  apollo: {
    adminAccounts: {
      query: QUERY_GET_ACCOUNTS,
      fetchPolicy: 'network-only',
      variables: {
        franchise: true
      }
    },
    adminERPs: {
      query: QUERY_GET_ERPS,
      fetchPolicy: 'network-only'
    },
    retailers: {
      query: QUERY_GET_RETAILERS_PAGINATED,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { filters: {} },
          {
            pagination: {
              page: 1,
              pageSize: 9999,
              search: '',
              sort: ['_id'],
              sortDirection: 'ASC'
            }
          }
        )
      },
      update({ clients: { count, data } }) {
        const result = data.map(item => {
          return {
            _id: item._id,
            formated_name: `[${item.id_client}] ${item.fantasy_name} | ${cnpj(item.cnpj)}`
          }
        })
        this.retailersData = result
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  watch: {
    isFranchisee() {
      this.selectedFranchise = ''
    }
  },
  methods: {
    startTour() {
      const tour = this.$shepherd({
        useModalOverlay: true
      })
      tour.addStep({
        title: 'Nome da Conta',
        text: 'Campo destinado ao nome do novo cliente. Este campo não pode ter caractere especial.',
        attachTo: { element: '#first-section', on: 'bottom' },
        cancelIcon: { enabled: true },
        buttons: [
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Acrônimo do Cliente',
        text:
          'Alguns clientes possuem nomes grandes, com isso, utilizamos palavra formada pela inicial ou por mais de uma letra para sua identificação interna. Este campo é gerado automaticamente',
        cancelIcon: { enabled: true },
        attachTo: { element: '#second-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Inicio Fidelidade do cliente',
        text: 'Data inicio da implantação do novo cliente',
        cancelIcon: { enabled: true },
        attachTo: { element: '#third-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'DB Name',
        text: 'Campo único de identificação do cliente para Mercafacil',
        cancelIcon: { enabled: true },
        attachTo: { element: '#fourth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'ERP',
        text: 'Nome do sistema do cliente. Neste campo é selecionado o ERP que o cliente está utilizando',
        cancelIcon: { enabled: true },
        attachTo: { element: '#fifth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Tipo de integração do cliente',
        text: 'Neste campo deve ser selecionado o tipo de integração',
        cancelIcon: { enabled: true },
        attachTo: { element: '#sixth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Plataforma de Teste',
        text: 'Em casos que o cliente seja uma plataforma de teste',
        cancelIcon: { enabled: true },
        attachTo: { element: '#seventh-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Franquia',
        text: 'Caso o cliente seja Franqueado, deve ser selecionado a flag e em seguida deve selecionar qual é a franquia',
        cancelIcon: { enabled: true },
        attachTo: { element: '#eighth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'E-commerce e CBM',
        text: 'Caso seja uma conta destinada à um E-commerce ou CBM, deverá ser selecionado as opções',
        cancelIcon: { enabled: true },
        attachTo: { element: '#ninth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Varejista',
        text: 'Neste campo deve ser selecionado o varejista e o vinculando a conta',
        cancelIcon: { enabled: true },
        attachTo: { element: '#tenth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Indústria',
        text: `Identifica se essa conta é Mercapromo, o que habilita as funcionalidades destinadas à esse tipo de oferta, 
        como liberação de acesso à páginas específicas e relatórios. 
        Uma conta Mercapromo pode ou não ter CBM contratado. Selecionando “com CBM” libera o acesso às demais funcionalidades da plataforma.`,
        cancelIcon: { enabled: true },
        attachTo: { element: '#eleventh-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Conexão',
        text: `Com a distribuição de conexões "Tenant" entre Homologação, SMB, KA e RAF, é necessário selecionar a conexão correta para a conta.
        Uma observação importante é que a conexão "Padrão" será descontinuada.`,
        cancelIcon: { enabled: true },
        attachTo: { element: '#twelfth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Completar',
            action: tour.complete
          }
        ]
      })
      tour.start()
    },
    async create() {
      this.$v.$reset()
      this.$v.$touch()
      if (this.$v.$error) {
        return this.$snackbar({ message: 'Preencha todos os campos corretamente', snackbarColor: '#F44336' })
      }

      this.disableCreate = true
      this.loading = true

      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_CREATE_ACCOUNT,
          variables: {
            name: this.name,
            acronym: this.acronym,
            erp: this.selectedERP.acronym,
            franchise: this.selectedFranchise,
            integration_mode: this.integrationMode,
            loyalty_program_start_date: this.loyaltyProgramStartDate,
            status: this.accountStatus,
            ecommerce_active: this.isEcommerce,
            cbm_active: this.isCbm,
            client_id: this.selectedRetailer,
            has_mercapromo: this.hasMercapromo,
            only_mercapromo: this.hasMercapromo && !this.mercapromoWithCbm,
            connection: this.connection
          }
        })

        this.loading = false

        setTimeout(() => {
          this.disableCreate = false
          this.$router.push(`/accounts/${data.adminCreateAccount._id}/edit/geral`)
          this.$snackbar({ message: 'Conta criada com sucesso', snackbarColor: '#2E7D32' })
        }, 300)
      } catch (e) {
        console.log(e.message)
        this.$snackbar({ message: 'Falha ao criar conta', snackbarColor: '#F44336' })
      }
      this.loading = false
    }
  },
  validations() {
    const baseValidation = {
      selectedERP: {
        acronym: {
          required
        }
      },
      dbName: {
        maxLength: maxLength(60)
      },
      loyaltyProgramStartDate: {
        required
      },
      name: {
        required
      },
      acronym: {
        required
      },
      // Prod accounts need to be enforced by the retailer
      ...(this.accountStatus === 'deploying'
        ? {
            selectedRetailer: {
              required
            }
          }
        : {})
    }
    if (this.isFranchisee) {
      baseValidation.selectedFranchise = { required }
    }
    return baseValidation
  }
}
</script>
